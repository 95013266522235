import { useState, useEffect, useRef } from "react";
import "./popup.css";
import popupimg from "./../../assets/img/popupimg.png";
import close from "./../../assets/img/close.png";
import emailjs from "emailjs-com";

function Popup(props) {
  const form = useRef();

  const [name, setname] = useState("");
  const nameonchangehandler = (e) => {
    setname(e.target.value);
  };

  const [email, setemail] = useState("");
  const emailonchangehandler = (e) => {
    setemail(e.target.value);
  };

  const [phone, setphone] = useState("");
  const phoneonchangehandler = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    setphone(numericValue);
  };

  const [bookdate, setbookdate] = useState("");
  const bookdateonchangehandler = (e) => {
    setbookdate(e.target.value);
  };
  const [message, setmessage] = useState("");
  const messageonchangehandler = (e) => {
    setmessage(e.target.value);
  };

  const closepopup = () => {
    props.setpopupdisplay("none");
  };

  const sendEmail = (e) => {
    console.log("sending email");
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mtrgzn4",
        "template_5h43tmp",
        form.current,
        "Adx2z66adQ-5yfhHj"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Sent.");
          setname("");
          setemail("");
          setphone("");
          setbookdate("");
          setmessage("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="popup_outercontainer" style={{ display: props.display }}>
      <div className="popup_innercontainer">
        <div className="close" onClick={closepopup}>
          <img src={close} alt="" />
        </div>
        <div className="popupimg_container">
          <img src={popupimg} alt="" height={225} />
          <h2>{props.location}</h2>
          <p>
            We are pleased to offer a dine-in experience for groups of up to six
            guests. Please note that this is available by reservation only, as
            our tasting room is located within our central production facility.
            To ensure a spot, we kindly ask that you book in advance.
          </p>
          <form ref={form} autoComplete="off" onSubmit={sendEmail}>
            <div className="form_group">
              <input
                type="text"
                name="name"
                value={name}
                onChange={nameonchangehandler}
                placeholder="Full Name"
                id="name"
                autoFocus
                required
              />
              <input
                type="email"
                name="email"
                value={email}
                onChange={emailonchangehandler}
                placeholder="Email"
                id="email"
                required
              />
            </div>
            <div className="form_group">
              <input
                type="text"
                name="phone"
                value={phone}
                onChange={phoneonchangehandler}
                placeholder="Contact No."
                id="phone"
                required
                inputMode="numeric" // Ensures numeric keyboard on mobile
                pattern="[0-9]*" // Ensures numeric input pattern
              />
              <input
                type="date"
                name="bookdate"
                value={bookdate}
                onChange={bookdateonchangehandler}
                placeholder="Date of Booking"
                id="bookdate"
                required
              />
            </div>
            <div className="form_group">
              <input
                type="hidden"
                name="location"
                value={props.location}
                readOnly
                placeholder="Store Location"
                id="location"
              />
            </div>
            <div className="form_group">
              <input
                type="text"
                name="message"
                value={message}
                onChange={messageonchangehandler}
                placeholder="Your Message"
                id="message"
                required
                style={{ width: "100%" }}
              />
            </div>
            <div className="">
              <button type="submit" name="button">
                Book Now
              </button>
              <span>
                Someone from our team will contact you to confirm the
                availability and discuss menu options.
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Popup;
