import { useState, useEffect, useRef } from 'react';
import './noformpopup.css';
import popupimg from './../../assets/img/popupimg.png';
import close from './../../assets/img/close.png';
import emailjs from 'emailjs-com';

function Noformpopup(props){

  const form = useRef();


  const closepopup = () => {
    props.setpopupdisplay('none')
  }


  return (
    <div className="nfpopup_outercontainer" style={{display:props.display}}>
      <div className="nfpopup_innercontainer">
      <div className="close" onClick={closepopup}>
        <img src={close} alt="" />
        </div>
        <div className="nfpopupimg_container">
          <img src={popupimg} alt="" />
          <h2>{props.location}</h2>
          <p style={{ fontSize: 20 }}>Boxpark Croydon offers a lively dining experience with open-air and covered seating on a first-come, first-served basis, alongside Live and energetic events.</p>
          <form  ref={form} autoComplete="off">
            <div className="form_group">
            <h4>Our Address: </h4>
            <h3>
                {props.address1}<br/>{props.address2}
            </h3>

              <input type="hidden" name="location" value={props.location} readOnly placeholder="Store Location" id="location"/>
            </div>
            <div className="">
              <button type="button" onClick={closepopup} name="close">Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Noformpopup;
